define("ember-lifeline/utils/get-or-allocate", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getOrAllocate;

  /**
   * Lazy allocates a property on an object
   *
   * @export
   * @param {Object} obj
   * @param {String} propertyName
   * @param {any} Type
   * @public
   * @returns the value of the newly allocated property
   */
  function getOrAllocate(obj, propertyName, Type) {
    if (!obj[propertyName]) {
      obj[propertyName] = new Type();
    }

    return obj[propertyName];
  }
});