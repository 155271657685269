define('ember-validations/errors', ['exports', 'ember'], function (exports, _ember) {
  var emberArray = _ember['default'].A;
  var EmberObject = _ember['default'].Object;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  var set = _ember['default'].set;
  exports['default'] = EmberObject.extend({
    unknownProperty: function unknownProperty(property) {
      var _this = this;

      run.once(function () {
        set(_this, property, emberArray());
      });
      return emberArray();
    }
  });
});