define('ember-light-table/mixins/table-header', ['exports', 'ember-light-table/utils/css-styleify'], function (exports, _cssStyleify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    attributeBindings: ['style'],

    scrollbarThickness: Ember.inject.service(),

    /**
     * @property table
     * @type {Table}
     * @private
     */
    table: null,

    /**
     * @property sharedOptions
     * @type {Object}
     * @private
     */
    sharedOptions: null,

    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,

    /**
     * @property extra
     * @type {Object}
     */
    extra: null,

    /**
     * @property fixed
     * @type {Boolean}
     * @default false
     */
    fixed: false,

    /**
     * @property sortOnClick
     * @type {Boolean}
     * @default true
     */
    sortOnClick: true,

    /**
     * @property multiColumnSort
     * @type {Boolean}
     * @default false
     */
    multiColumnSort: false,

    /**
     * Resize all cells in the column instead of just the header / footer
     *
     * @property resizeOnDrag
     * @type {Boolean}
     * @default false
     */
    resizeOnDrag: false,

    /**
     * CSS classes to be applied to an `<i class="lt-sort-icon"></i>` tag that is
     * inserted into the column's `<th>` element when the column is sortable but
     * not yet sorted.
     *
     * For instance, if you have installed `ember-font-awesome` or include the
     * `font-awesome` assets manually (e.g. via a CDN), you can set
     * `iconSortable` to `'fa fa-sort'`, which would yield this markup:
     * `<i class="lt-sort-icon fa fa-sort"></i>`
     *
     * @property iconSortable
     * @type {String}
     * @default ''
     */
    iconSortable: '',

    /**
     * See `iconSortable`.  CSS classes to apply to `<i class="lt-sort-icon"></i>`
     * when the column is sorted ascending.
     *
     * @property iconAscending
     * @type {String}
     * @default ''
     */
    iconAscending: '',

    /**
     * See `iconSortable`.  CSS classes to apply to `<i class="lt-sort-icon"></i>`
     * when the column is sorted descending.
     *
     * @property iconDescending
     * @type {String}
     * @default ''
     */
    iconDescending: '',

    /**
     * Custom sorting component name to use instead of the default `<i class="lt-sort-icon"></i>` template.
     * See `iconSortable`, `iconAsending`, or `iconDescending`.
     * @property iconComponent
     * @type {String}
     * @default false
     */
    iconComponent: null,

    /**
     * ID of main table component. Used to generate divs for ember-wormhole
     * @type {String}
     */
    tableId: null,

    renderInPlace: Ember.computed.oneWay('fixed'),
    columnGroups: Ember.computed.readOnly('table.visibleColumnGroups'),
    subColumns: Ember.computed.readOnly('table.visibleSubColumns'),
    columns: Ember.computed.readOnly('table.visibleColumns'),

    sortIcons: Ember.computed('iconSortable', 'iconAscending', 'iconDescending', 'iconComponent', function () {
      return this.getProperties(['iconSortable', 'iconAscending', 'iconDescending', 'iconComponent']);
    }).readOnly(),

    style: Ember.computed('sharedOptions.occlusion', function () {
      if (this.get('sharedOptions.occlusion')) {
        var scrollbarThickness = this.get('scrollbarThickness.thickness');
        return (0, _cssStyleify.default)({ paddingRight: scrollbarThickness + 'px' });
      }
    }).readOnly(),

    init: function init() {
      this._super.apply(this, arguments);

      var fixed = this.get('fixed');
      var height = this.get('sharedOptions.height');

      (true && Ember.warn('You did not set a `height` attribute for your table, but marked a header or footer to be fixed. This means that you have to set the table height via CSS. For more information please refer to:  https://github.com/offirgolan/ember-light-table/issues/446', !fixed || fixed && !Ember.isEmpty(height), { id: 'ember-light-table.height-attribute' }));
    },


    actions: {
      /**
       * onColumnClick action. Handles column sorting.
       *
       * @event onColumnClick
       * @param  {Column} column The column that was clicked
       * @param  {Event} event The click event
       */
      onColumnClick: function onColumnClick(column) {
        if (column.sortable && this.get('sortOnClick')) {
          if (column.sorted) {
            column.toggleProperty('ascending');
          } else {
            if (!this.get('multiColumnSort')) {
              this.get('table.sortedColumns').setEach('sorted', false);
            }
            column.set('sorted', true);
          }
        }
        this.sendAction.apply(this, ['onColumnClick'].concat(Array.prototype.slice.call(arguments)));
      },


      /**
       * onColumnDoubleClick action.
       *
       * @event onColumnDoubleClick
       * @param  {Column} column The column that was clicked
       * @param  {Event} event   The click event
       */
      onColumnDoubleClick: function onColumnDoubleClick() /* column */{
        this.sendAction.apply(this, ['onColumnDoubleClick'].concat(Array.prototype.slice.call(arguments)));
      },


      /**
       * onColumnResized action.
       *
       * @event onColumnResized
       * @param  {Column} column The column that was resized
       * @param  {String} width  The final width of the column
       */
      onColumnResized: function onColumnResized() /* column, width */{
        this.sendAction.apply(this, ['onColumnResized'].concat(Array.prototype.slice.call(arguments)));
      },


      /**
       * onColumnDrag action.
       *
       * @event onColumnDrag
       * @param  {Column} column The column that is being dragged
       */
      onColumnDrag: function onColumnDrag() /* column */{
        this.sendAction.apply(this, ['onColumnDrag'].concat(Array.prototype.slice.call(arguments)));
      },


      /**
       * onColumnDrop action.
       *
       * @event onColumnDrop
       * @param  {Column} column The column that was dropped
       * @param  {Boolean} isSuccess The column was successfully dropped and sorted
       */
      onColumnDrop: function onColumnDrop() /* column, isSuccess */{
        this.sendAction.apply(this, ['onColumnDrop'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});