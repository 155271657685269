define('ember-light-table/components/lt-head', ['exports', 'ember-light-table/templates/components/lt-head', 'ember-light-table/mixins/table-header'], function (exports, _ltHead, _tableHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tableHeader.default, {
    layout: _ltHead.default,
    classNames: ['lt-head-wrap'],
    table: null,
    sharedOptions: null,

    init: function init() {
      this._super.apply(this, arguments);

      Ember.trySet(this, 'sharedOptions.fixedHeader', Ember.get(this, 'fixed'));
    }
  });
});