define('ember-ui-sortable/components/ui-sortable', ['exports', 'ember', 'ember-ui-sortable/templates/components/ui-sortable'], function (exports, _ember, _emberUiSortableTemplatesComponentsUiSortable) {
  var get = _ember['default'].get;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberUiSortableTemplatesComponentsUiSortable['default'],
    disabled: false,

    tagName: 'ul',

    uiOptions: ['axis', 'appendTo', 'connectWith', 'containment', 'cursor', 'cursorAt', 'delay', 'disabled', 'distance', 'forceHelperSize', 'forcePlaceholderSize', 'grid', 'handle', 'helper', 'opacity', 'placeholder', 'revert', 'scroll', 'scrollSensitivity', 'scrollSpeed', 'tolerance', 'zIndex'],

    destroySortable: on('willDestroyElement', function () {
      this.$().sortable('destroy');
    }),

    initSortable: on('didInsertElement', function () {
      var _this = this;

      var opts = {};

      ['start', 'stop', 'out', 'over'].forEach(function (callback) {
        opts[callback] = run.bind(_this, callback);
      });

      this.$().sortable(opts);

      this.get('uiOptions').forEach(function (option) {
        _this._bindSortableOption(option);
      });
    }),

    contentObserver: observer('content.[]', function () {
      run.scheduleOnce('afterRender', this, this._refreshSortable);
    }),

    move: function move(oldIndex, newIndex) {
      var content = this.get('content');
      var moved = this.get('moved');

      if (content && moved) {
        var item = content.objectAt(oldIndex);
        return moved(item, oldIndex, newIndex);
      }
    },

    over: function over(event, ui) {
      if (this.attrs.over) {
        this.attrs.over(event, ui);
      }
    },

    out: function out(event, ui) {
      if (this.attrs.out) {
        this.attrs.out(event, ui);
      }
    },

    start: function start(event, ui) {
      ui.item.data('oldIndex', ui.item.index());
    },

    stop: function stop(event, ui) {
      var item = ui.item;
      var newIndex = ui.item.index();

      if (ui.item.hasClass('draggable-item')) {
        item.detach();
        this.attrs.inserted(item, newIndex);
      } else {
        var oldIndex = ui.item.data('oldIndex');
        this.move(oldIndex, newIndex);
      }
    },

    _bindSortableOption: function _bindSortableOption(key) {
      this.addObserver(key, this, this._optionDidChange);

      if (key in this) {
        this._optionDidChange(this, key);
      }

      this.on('willDestroyElement', this, function () {
        this.removeObserver(key, this, this._optionDidChange);
      });
    },

    _optionDidChange: function _optionDidChange(sender, key) {
      this.$().sortable('option', key, this.get(key));
    },

    _refreshSortable: function _refreshSortable() {
      if (this.isDestroying) {
        return;
      }
      this.$().sortable('refresh');
    }
  });
});