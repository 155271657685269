define('ember-file-upload/system/drag-listener', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var _class = function () {
    function _class() {
      _classCallCheck(this, _class);

      this._listeners = Ember.A();
      this._stack = [];

      // Keep a stack of deferred actions to take
      // on listeners to provide sane events.
      // `dragleave` / `dragenter` are called on the
      // same element back to back, which isn't what
      // we want to provide as an API.
      this._events = Ember.A();
    }

    _createClass(_class, [{
      key: 'beginListening',
      value: function beginListening() {
        var handlers = this._handlers = {
          dragenter: Ember.run.bind(this, 'dragenter'),
          dragleave: Ember.run.bind(this, 'dragleave'),
          dragover: Ember.run.bind(this, 'dragover'),
          drop: Ember.run.bind(this, 'drop')
        };

        var body = document.body;
        body.addEventListener('dragenter', handlers.dragenter, {
          passive: true
        });
        body.addEventListener('dragleave', handlers.dragleave, {
          passive: true
        });
        body.addEventListener('dragover', handlers.dragover, {
          passive: false
        });
        body.addEventListener('drop', handlers.drop, {
          passive: false
        });
      }
    }, {
      key: 'endListening',
      value: function endListening() {
        var body = document.body;
        var handlers = this._handlers;
        body.removeEventListener('dragenter', handlers.dragenter, {
          passive: true
        });
        body.removeEventListener('dragleave', handlers.dragleave, {
          passive: true
        });
        body.removeEventListener('dragover', handlers.dragover, {
          passive: false
        });
        body.removeEventListener('drop', handlers.drop, {
          passive: false
        });
      }
    }, {
      key: 'addEventListeners',
      value: function addEventListeners(selector, handlers) {
        if (this._listeners.length === 0) {
          this.beginListening();
        }

        // Listeners are ordered by most specific to least specific
        var insertAt = this._listeners.length;

        for (var i = 0, len = this._listeners.length; i < len; i++) {
          var listener = this._listeners[i];
          (true && !(document.querySelector(selector) !== document.querySelector(listener.selector)) && Ember.assert('Cannot add multiple listeners for the same element ' + selector + ', ' + listener.selector, document.querySelector(selector) !== document.querySelector(listener.selector)));


          if (document.querySelector(listener.selector + ' ' + selector)) {
            insertAt = i;
          }
        }

        this._listeners.splice(insertAt, 0, { selector: selector, handlers: handlers });
      }
    }, {
      key: 'removeEventListeners',
      value: function removeEventListeners(selector) {
        this._listeners.removeObject(this._listeners.findBy('selector', selector));
        if (this._listeners.length === 0) {
          this.endListening();
        }
      }
    }, {
      key: 'findListener',
      value: function findListener(evt) {
        return this._listeners.find(function (_ref) {
          var selector = _ref.selector;

          var element = document.querySelector(selector);
          return element === evt.target || element.contains(evt.target);
        });
      }
    }, {
      key: 'getEventSource',
      value: function getEventSource(evt) {
        var types = evt.dataTransfer.types || [];
        var areSomeTypesFiles = false;
        for (var i = 0, len = types.length; i < len; i++) {
          if (types[i] === 'Files' || types[i] === 'application/x-moz-file') {
            areSomeTypesFiles = true;
            break;
          }
        }
        return areSomeTypesFiles ? 'os' : 'web';
      }
    }, {
      key: 'getDataTransferItemDetails',
      value: function getDataTransferItemDetails(evt) {
        var itemDetails = [];
        for (var i = 0; i < evt.dataTransfer.items.length; i++) {
          var item = evt.dataTransfer.items[i];
          itemDetails.push({
            kind: item.kind,
            type: item.type
          });
        }
        return itemDetails;
      }
    }, {
      key: 'dragenter',
      value: function dragenter(evt) {
        var listener = this.findListener(evt);
        var lastListener = this._stack[this._stack.length - 1];

        // Trigger dragleave on the previous listener
        if (lastListener) {
          this.scheduleEvent('dragleave', lastListener, evt);
        }

        if (listener) {
          this.scheduleEvent('dragenter', listener, {
            source: this.getEventSource(evt),
            dataTransfer: evt.dataTransfer,
            itemDetails: this.getDataTransferItemDetails(evt)
          });
        }
        this._listener = listener;
      }
    }, {
      key: 'dragleave',
      value: function dragleave(evt) {
        // Trigger a dragleave if the file leaves the browser
        if (this._stack.length) {
          this.scheduleEvent('dragleave', this._stack[0], evt);
          this._listener = null;
        }
      }
    }, {
      key: 'dragover',
      value: function dragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        var listener = this.findListener(evt);
        if (listener) {
          if (this._listener) {
            this.scheduleEvent('dragleave', this._listener, evt);
          }
          this.scheduleEvent('dragenter', listener, {
            source: this.getEventSource(evt),
            dataTransfer: evt.dataTransfer,
            itemDetails: this.getDataTransferItemDetails(evt)
          });
          if (this._stack.indexOf(listener) !== -1) {
            listener.handlers.dragover(evt);
          }
        }
        this._listener = listener;
      }
    }, {
      key: 'scheduleEvent',
      value: function scheduleEvent(eventName, listener, event) {
        var isDuplicate = this._events.find(function (queuedEvent) {
          return queuedEvent.eventName === eventName && queuedEvent.listener === listener;
        });

        var cancelledEvent = this._events.find(function (queuedEvent) {
          return queuedEvent.listener === listener && queuedEvent.eventName === 'dragleave' && eventName === 'dragenter' || queuedEvent.eventName === 'dragenter' && eventName === 'dragleave';
        });

        if (cancelledEvent) {
          this._events.removeObject(cancelledEvent);
          if (this._events.length === 0) {
            Ember.run.cancel(this._scheduled);
            this._scheduled = null;
          }
        } else if (!isDuplicate) {
          this._events.push({ eventName: eventName, listener: listener, event: event });
          if (!this._scheduled) {
            this._scheduled = Ember.run.next(this, 'sendEvents');
          }
        }
      }
    }, {
      key: 'sendEvents',
      value: function sendEvents() {
        var _this = this;

        this._events.forEach(function (_ref2) {
          var eventName = _ref2.eventName,
              listener = _ref2.listener,
              event = _ref2.event;

          if (eventName === 'dragenter') {
            _this._stack.push(listener);
          } else if (eventName === 'dragleave') {
            _this._stack.pop();
          }
          listener.handlers[eventName](event);
        });

        this._events = Ember.A();
        this._scheduled = false;
      }
    }, {
      key: 'drop',
      value: function drop(evt) {
        this._stack = [];
        this._events = Ember.A();
        this._scheduled = false;
        this._listener = null;

        evt.preventDefault();
        evt.stopPropagation();
        var listener = this.findListener(evt);
        if (listener) {
          listener.handlers.drop(evt);
        }
      }
    }]);

    return _class;
  }();

  exports.default = _class;
});