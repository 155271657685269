define('ember-lifeline/mixins/run', ['exports', 'ember-lifeline/utils/get-or-allocate'], function (exports, _getOrAllocate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setShouldPoll = setShouldPoll;
  exports.pollTaskFor = pollTaskFor;
  exports.cancelBoundTasks = cancelBoundTasks;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _shouldPollOverride = void 0;
  function shouldPoll() {
    if (_shouldPollOverride) {
      return _shouldPollOverride();
    }

    // eslint-disable-next-line ember-suave/no-direct-property-access
    return !Ember.testing;
  }

  function setShouldPoll(callback) {
    _shouldPollOverride = callback;
  }

  var queuedPollTasks = Object.create(null);
  var pollTaskLabels = Object.create(null);
  function pollTaskFor(label) {
    (true && !(pollTaskLabels[label]) && Ember.assert('A pollTask with a label of \'' + label + '\' was not found.', pollTaskLabels[label]));
    (true && !(!!queuedPollTasks[label]) && Ember.assert('You cannot advance a pollTask (`' + label + '`) when `next` has not been called.', !!queuedPollTasks[label]));


    return Ember.run.join(null, queuedPollTasks[label]);
  }

  /**
   ContextBoundTasksMixin provides a mechanism to run tasks (ala `setTimeout` or
   `Ember.run.later`) with automatic cancellation when the host object is
   destroyed.
  
   These capabilities are very commonly needed, so this mixin is by default
   included into all `Ember.View`, `Ember.Component`, and `Ember.Service` instances.
  
   @class ContextBoundTasksMixin
   @public
   */
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this._pendingTimers = undefined;
      this._pendingDebounces = undefined;
      this._pendingThrottles = undefined;
      this._pollerLabels = undefined;
    },


    /**
     Runs the provided function at the specified timeout (defaulting to 0).
     The timer is properly canceled if the object is destroyed before it is invoked.
      Example:
      ```js
     import Component from 'ember-component';
     import ContextBoundTasksMixin from 'ember-lifeline/mixins/run';
      export default Component.extend(ContextBoundTasksMixin, {
       didInsertElement() {
         this.runTask(() => {
           console.log('This runs after 5 seconds if this component is still displayed');
         }, 5000)
       }
     });
     ```
      @method runTask
     @param { Function } callbackOrName the callback to run at the provided time
     @param { Number } [timeout=0] the time in the future to run the callback
     @public
     */
    runTask: function runTask(callbackOrName) {
      var _this = this;

      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      (true && !(!this.isDestroyed) && Ember.assert('Called `runTask` on destroyed object: ' + this + '.', !this.isDestroyed));


      var type = typeof callbackOrName === 'undefined' ? 'undefined' : _typeof(callbackOrName);
      var pendingTimers = (0, _getOrAllocate.default)(this, '_pendingTimers', Array);

      var cancelId = Ember.run.later(function () {
        var cancelIndex = pendingTimers.indexOf(cancelId);
        pendingTimers.splice(cancelIndex, 1);

        if (type === 'function') {
          callbackOrName.call(_this);
        } else if (type === 'string' && _this[callbackOrName]) {
          _this[callbackOrName]();
        } else {
          throw new Error('You must pass a callback function or method name to `runTask`.');
        }
      }, timeout);

      pendingTimers.push(cancelId);
      return cancelId;
    },


    /**
     Cancel a previously scheduled task.
      Example:
      ```js
     import Component from 'ember-component';
     import ContextBoundTasksMixin from 'ember-lifeline/mixins/run';
      export default Component.extend(ContextBoundTasksMixin, {
       didInsertElement() {
         this._cancelId = this.runTask(() => {
           console.log('This runs after 5 seconds if this component is still displayed');
         }, 5000)
       },
        disable() {
          this.cancelTask(this._cancelId);
       }
     });
     ```
      @method cancelTask
     @param { Number } cancelId the id returned from the runTask or scheduleTask call
     @public
     */
    cancelTask: function cancelTask(cancelId) {
      cancelTimer(cancelId);
    },


    /**
     Adds the provided function to the named queue to be executed at the end of the RunLoop.
     The timer is properly canceled if the object is destroyed before it is invoked.
      Example:
      ```js
     import Component from 'ember-component';
     import ContextBoundTasksMixin from 'web-client/mixins/context-bound-tasks';
      export default Component.extend(ContextBoundTasksMixin, {
       init() {
         this._super(...arguments);
         this.scheduleTask('actions', () => {
           console.log('This runs at the end of the run loop (via the actions queue) if this component is still displayed');
         })
       }
     });
     ```
      @method scheduleTask
     @param { String } queueName the queue to schedule the task into
     @param { Function } callbackOrName the callback to run at the provided time
     @param { ...* } args arguments to pass to the callback
     @public
     */
    scheduleTask: function scheduleTask(queueName, callbackOrName) {
      (true && !(typeof queueName === 'string') && Ember.assert('Called `scheduleTask` without a string as the first argument on ' + this + '.', typeof queueName === 'string'));
      (true && !(queueName !== 'afterRender') && Ember.assert('Called `scheduleTask` while trying to schedule to the `afterRender` queue on ' + this + '.', queueName !== 'afterRender'));
      (true && !(!this.isDestroyed) && Ember.assert('Called `scheduleTask` on destroyed object: ' + this + '.', !this.isDestroyed));


      var callback = void 0;
      var type = typeof callbackOrName === 'undefined' ? 'undefined' : _typeof(callbackOrName);
      if (type === 'function') {
        callback = callbackOrName;
      } else if (type === 'string') {
        callback = this[callbackOrName];
      }

      (true && !(typeof callback === 'function') && Ember.assert('You must pass a callback function or method name to `scheduleTask`.', typeof callback === 'function'));

      for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      var cancelId = Ember.run.schedule.apply(Ember.run, [queueName, this, callback].concat(_toConsumableArray(args)));

      var pendingTimers = (0, _getOrAllocate.default)(this, '_pendingTimers', Array);
      pendingTimers.push(cancelId);

      return cancelId;
    },


    /**
     Runs the function with the provided name after the timeout has expired on the last
     invocation. The timer is properly canceled if the object is destroyed before it is
     invoked.
      Example:
      ```js
     import Component from 'ember-component';
     import ContextBoundTasksMixin from 'ember-lifeline/mixins/run';
      export default Component.extend(ContextBoundTasksMixin, {
       logMe() {
         console.log('This will only run once every 300ms.');
       },
        click() {
         this.debounceTask('logMe', 300);
       }
     });
     ```
      @method debounceTask
     @param { String } methodName the name of the method to debounce
     @param { ...* } debounceArgs arguments to pass to the debounced method
     @param { Number } wait the amount of time to wait before calling the method (in milliseconds)
     @public
     */
    debounceTask: function debounceTask(name) {
      var _this2 = this;

      (true && !(typeof name === 'string') && Ember.assert('Called `debounceTask` without a string as the first argument on ' + this + '.', typeof name === 'string'));
      (true && !(typeof this[name] === 'function') && Ember.assert('Called `this.debounceTask(\'' + name + '\', ...)` where \'this.' + name + '\' is not a function.', typeof this[name] === 'function'));
      (true && !(!this.isDestroyed) && Ember.assert('Called `debounceTask` on destroyed object: ' + this + '.', !this.isDestroyed));


      var pendingDebounces = (0, _getOrAllocate.default)(this, '_pendingDebounces', Object);
      var debounce = pendingDebounces[name];
      var debouncedFn = void 0;

      if (!debounce) {
        debouncedFn = function debouncedFn() {
          delete pendingDebounces[name];
          _this2[name].apply(_this2, arguments);
        };
      } else {
        debouncedFn = debounce.debouncedFn;
      }

      // cancelId is new, even if the debounced function was already present

      for (var _len2 = arguments.length, debounceArgs = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        debounceArgs[_key2 - 1] = arguments[_key2];
      }

      var cancelId = Ember.run.debounce.apply(Ember.run, [this, debouncedFn].concat(_toConsumableArray(debounceArgs)));

      pendingDebounces[name] = { debouncedFn: debouncedFn, cancelId: cancelId };
    },


    /**
     Cancel a previously debounced task.
      Example:
      ```js
     import Component from 'ember-component';
     import ContextBoundTasksMixin from 'ember-lifeline/mixins/run';
      export default Component.extend(ContextBoundTasksMixin, {
       logMe() {
         console.log('This will only run once every 300ms.');
       },
        click() {
         this.debounceTask('logMe', 300);
       },
        disable() {
          this.cancelDebounce('logMe');
       }
     });
     ```
      @method cancelDebounce
     @param { String } methodName the name of the debounced method to cancel
     @public
     */
    cancelDebounce: function cancelDebounce(name) {
      _cancelDebounce(this._pendingDebounces, name);
    },
    cancelThrottle: function cancelThrottle(cancelId) {
      _cancelThrottle(cancelId);
    },


    /**
     Runs the function with the provided name immediately, and only once in the time window
     specified by the timeout argument.
      Example:
      ```js
     import Component from 'ember-component';
     import ContextBoundTasksMixin from 'ember-lifeline/mixins/run';
      export default Component.extend(ContextBoundTasksMixin, {
       logMe() {
         console.log('This will run once immediately, then only once every 300ms.');
       },
        click() {
         this.throttleTask('logMe', 300);
       }
     });
     ```
      @method throttleTask
     @param { String } functionName the name of the function to debounce
     @param { Number } [timeout=5] the time in the future to run the callback (defaults to 5ms)
     @public
     */
    throttleTask: function throttleTask(name) {
      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      (true && !(typeof name === 'string') && Ember.assert('Called `throttleTask` without a string as the first argument on ' + this + '.', typeof name === 'string'));
      (true && !(typeof this[name] === 'function') && Ember.assert('Called `this.throttleTask(\'' + name + '\', ' + timeout + ')` where \'this.' + name + '\' is not a function.', typeof this[name] === 'function'));
      (true && !(!this.isDestroyed) && Ember.assert('Called `throttleTask` on destroyed object: ' + this + '.', !this.isDestroyed));


      var pendingThrottles = (0, _getOrAllocate.default)(this, '_pendingThrottles', Array);

      var cancelId = Ember.run.throttle(this, name, timeout);

      pendingThrottles.push(cancelId);

      return cancelId;
    },


    /**
     Sets up a function that can perform polling logic in a testing safe way.
     The callback is invoked synchronously with an argument (generally called `next`).
     In normal development/production when `next` is invoked, it will trigger the
     task again (recursively). However, when in test mode the recursive polling
     functionality is disabled, and usage of the `pollTaskFor` helper is required.
      Example:
      ```js
     // app/components/foo-bar.js
     export default Component.extend({
       api: injectService(),
        init() {
         this._super(...arguments);
          this.pollTask((next) => {
           this.get('api').request('get', 'some/path')
             .then(() => {
               this.runTask(next, 1800);
             })
         }, 'foo-bar#watch-some-path');
       }
     });
     ```
      Test Example:
      ```js
     import wait from 'ember-test-helpers/wait';
     import { pollTaskFor } from 'ember-lifeline/mixins/run';
      //...snip...
      test('foo-bar watches things', function(assert) {
       this.render(hbs`{{foo-bar}}`);
        return wait()
         .then(() => {
           assert.equal(serverRequests, 1, 'called initially');
            pollTaskFor('foo-bar#watch-some-path');
           return wait();
         })
         .then(() => {
           assert.equal(serverRequests, 2, 'called again');
         });
     });
     ```
      @method pollTask
     @param { Function | String } callbackOrMethodName the callback or method name to run
     @param { String } [label] the label for the pollTask to be created
     @public
     */
    pollTask: function pollTask(callbackOrMethodName, label) {
      var _this3 = this;

      var next = void 0,
          callback = void 0;
      var type = typeof callbackOrMethodName === 'undefined' ? 'undefined' : _typeof(callbackOrMethodName);

      if (type === 'function') {
        callback = callbackOrMethodName;
      } else if (type === 'string' && this[callbackOrMethodName]) {
        callback = this[callbackOrMethodName];
      } else {
        throw new Error('You must pass a callback function or method name to `pollTask`.');
      }

      var tick = function tick() {
        return callback.call(_this3, next);
      };

      if (label) {
        (true && !(!pollTaskLabels[label]) && Ember.assert('The label provided to `pollTask` must be unique. `' + label + '` has already been registered.', !pollTaskLabels[label]));

        pollTaskLabels[label] = true;

        (0, _getOrAllocate.default)(this, '_pollerLabels', Array).push(label);
      }

      if (shouldPoll()) {
        next = tick;
      } else if (label) {
        next = function next() {
          queuedPollTasks[label] = tick;
        };
      } else {
        next = function next() {};
      }

      callback.call(this, next);
    },


    /**
     Clears a previously setup polling task.
      Example:
      ```js
     // app/components/foo-bar.js
     export default Component.extend({
       api: injectService(),
        enableAutoRefresh() {
         this.pollTask((next) => {
           this.get('api').request('get', 'some/path')
             .then(() => {
               this.runTask(next, 1800);
             })
         }, 'foo-bar#watch-some-path');
       },
        disableAutoRefresh() {
          this.cancelPoll('foo-bar#watch-some-path');
       }
     });
     ```
      @method cancelPoll
     @param { String } label the label for the pollTask to be cleared
     @public
     */
    cancelPoll: function cancelPoll(label) {
      _cancelPoll(label);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      cancelBoundTasks(this._pendingTimers, cancelTimer);
      cancelBoundTasks(this._pollerLabels, _cancelPoll);
      cancelBoundTasks(this._pendingThrottles, _cancelThrottle);
      cancelDebounces(this._pendingDebounces);
    }
  });
  function cancelBoundTasks(tasks, cancelFn) {
    if (!tasks || !tasks.length) {
      return;
    }

    for (var i = 0; i < tasks.length; i++) {
      cancelFn(tasks[i]);
    }
  }

  function cancelTimer(cancelId) {
    Ember.run.cancel(cancelId);
  }

  function _cancelPoll(label) {
    pollTaskLabels[label] = undefined;
    queuedPollTasks[label] = undefined;
  }

  function _cancelThrottle(cancelId) {
    Ember.run.cancel(cancelId);
  }

  function _cancelDebounce(pendingDebounces, name) {
    var cancelId = pendingDebounces[name].cancelId;

    Ember.run.cancel(cancelId);
  }

  function cancelDebounces(pendingDebounces) {
    var debounceNames = pendingDebounces && Object.keys(pendingDebounces);

    if (!debounceNames || !debounceNames.length) {
      return;
    }

    for (var i = 0; i < debounceNames.length; i++) {
      _cancelDebounce(pendingDebounces, debounceNames[i]);
    }
  }
});