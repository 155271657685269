define('ember-lifeline/mixins/disposable', ['exports', 'ember-lifeline/utils/get-or-allocate'], function (exports, _getOrAllocate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this._registeredDisposables = undefined;
    },


    /**
     Adds a new disposable to the Ember object. A disposable is a function that
     disposes of resources that are outside of Ember's lifecyle. This essentially
     means you can register a function that you want to run to automatically tear
     down any resources when the Ember object is destroyed.
      Example:
      ```js
     // app/components/foo-bar.js
     import Ember from 'ember';
     import DOMish from 'some-external-library';
      const { run } = Ember;
      export default Component.extend({
       init() {
         this.DOMish = new DOMish();
          this.bindEvents();
       },
        bindEvents() {
         let onFoo = run.bind(this.respondToDomEvent);
         this.DOMish.on('foo', onFoo);
          this.domFooDisposable = this.registerDisposable(() => this.DOMish.off('foo', onFoo));
       },
        respondToDOMEvent() {
         // do something
       }
     });
     ```
     @method registerDisposable
    @param { Function } dispose
    @returns A disposable object
    @public
    */
    registerDisposable: function registerDisposable(dispose) {
      (true && !(typeof dispose === 'function') && Ember.assert('Called \`registerDisposable\` where \`dispose\` is not a function', typeof dispose === 'function'));


      var disposables = (0, _getOrAllocate.default)(this, '_registeredDisposables', Array);
      var disposable = toDisposable(dispose);

      disposables.push(disposable);

      return disposable;
    },
    willDestroy: function willDestroy() {
      runDisposables(this._registeredDisposables);

      this._super.apply(this, arguments);
    }
  });


  function runDisposables(disposables) {
    if (!disposables) {
      return;
    }

    for (var i = 0, l = disposables.length; i < l; i++) {
      var disposable = disposables.pop();

      disposable.dispose();
    }
  }

  function toDisposable(doDispose) {
    return {
      dispose: function dispose() {
        if (!this.disposed) {
          this.disposed = true;
          doDispose();
        }
      },

      disposed: false
    };
  }
});